import React from "react";

import {
  StyledHeroArrow,
  StyledHeroContent,
  StyledHeroImage,
  StyledHeroImageWrapper,
  StyledHeroTitles,
  StyledHeroWrapper,
} from "./subpageHero.style";
import { StyledCenter } from "../../utils/styledComponents/center.style";
import { StyledBoldTitle } from "../../utils/styledComponents/boldTitle.style";
import { StyledRegularTitle } from "../../utils/styledComponents/regularTitle.style";
import OrangeArrow from "../../images/orangeArrow.png";
import PanelsImage from "../../images/panels.png";

type SubpageHeroPropsType = {
  children: React.ReactNode;
  title: string;
  subTitle: string;
};

const SubpageHero = ({
  children,
  title,
  subTitle,
}: SubpageHeroPropsType): JSX.Element => {
  return (
    <StyledHeroWrapper>
      <StyledCenter>
        <StyledHeroContent>
          <StyledHeroTitles>
            <StyledBoldTitle> {title}</StyledBoldTitle>
            <StyledRegularTitle>
              {subTitle}
              <StyledHeroArrow src={OrangeArrow} />
            </StyledRegularTitle>
          </StyledHeroTitles>
          <StyledHeroImageWrapper>
            <StyledHeroImage src={PanelsImage} />
          </StyledHeroImageWrapper>
        </StyledHeroContent>
      </StyledCenter>
      {children}
    </StyledHeroWrapper>
  );
};

export default SubpageHero;
