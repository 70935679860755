import React from "react";

import {
  StyledContactDiv,
  StyledContactForm,
  StyledContactIcon,
  StyledContactTitles,
  StyledContactWrapper,
} from "./contact.style";
import Form from "../form/form.component";
import { StyledBoldTitle } from "../../utils/styledComponents/boldTitle.style";
import { StyledRegularTitle } from "../../utils/styledComponents/regularTitle.style";
import { StyledCenter } from "../../utils/styledComponents/center.style";
import WhiteArrow from "../../images/whiteArrowIcon.png";
import { LANG } from "../../lang/lang";

const Contact = (): JSX.Element => {
  return (
    <StyledCenter>
      <StyledContactWrapper>
        <StyledContactDiv>
          <StyledContactTitles>
            <StyledBoldTitle>{LANG.PL.contactSection.title}</StyledBoldTitle>
            <StyledRegularTitle>
              {LANG.PL.contactSection.subTitle}
              <StyledContactIcon src={WhiteArrow} />
            </StyledRegularTitle>
          </StyledContactTitles>
          <StyledContactForm>
            <Form errorsMessage={LANG.PL.forms.errors} />
          </StyledContactForm>
        </StyledContactDiv>
      </StyledContactWrapper>
    </StyledCenter>
  );
};

export default Contact;
