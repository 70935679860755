import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { Link } from "gatsby";
import { LANG } from "../../lang/lang";
import { useFormData } from "../../shared/useFormData.hook";

import {
  StyledCheckboxContainer,
  StyledCheckboxLabel,
  StyledCheckboxMsg,
  StyledErrorMessage,
  StyledForm,
  StyledFormCheckbox,
  StyledFormInput,
  StyledFormItem,
  StyledFormLabel,
  StyledFormLastItem,
  StyledFormWrapper,
  StyledLinkButton,
  StyledMessage,
  StyledMessageBox,
} from "./form.style";
import Button from "../button/button.component";
import { Loader } from "../../utils/styledComponents/loader.style";

type FormValues = {
  name: string;
  phone: number;
  email: string;
  zipCode: number;
  city: string;
  policy: boolean;
};

type ErrorMessageElement = {
  email: string;
  phone: string;
  required: string;
  zipCode: string;
};

type FormPropsType = {
  errorsMessage: ErrorMessageElement;
};

const Form = ({ errorsMessage }: FormPropsType): JSX.Element => {
  const [value, setValue] = useState(null);
  const sendData = useFormData(value);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>();

  return (
    <>
      {sendData[1] && !sendData[2] && !sendData[0] && (
        <StyledMessageBox>
          <Loader />
        </StyledMessageBox>
      )}
      {!sendData[1] && !sendData[2] && sendData[0] && (
        <StyledMessageBox>
          <StyledMessage success={sendData[0]}>
            {LANG.PL.forms.succesMessage}
          </StyledMessage>
        </StyledMessageBox>
      )}
      {!sendData[1] && sendData[2] && !sendData[0] && (
        <StyledMessageBox>
          <StyledMessage success={false}>
            {LANG.PL.forms.errorMessage}
          </StyledMessage>
        </StyledMessageBox>
      )}
      {!sendData[1] && !sendData[0] && (
        <StyledFormWrapper>
          <StyledForm onSubmit={handleSubmit(setValue)}>
            <StyledFormItem>
              <StyledFormLabel>{LANG.PL.forms.inputs.name}</StyledFormLabel>
              <StyledFormInput
                {...register("name", {
                  required: errorsMessage.required,
                })}
              ></StyledFormInput>
              {errors.name && (
                <StyledErrorMessage>{errors.name.message}</StyledErrorMessage>
              )}
            </StyledFormItem>
            <StyledFormItem>
              <StyledFormLabel>{LANG.PL.forms.inputs.phone}</StyledFormLabel>
              <StyledFormInput
                {...register("phone", {
                  required: errorsMessage.required,
                  minLength: { value: 9, message: errorsMessage.phone },
                  pattern: {
                    value: /^\d+$/i,
                    message: errorsMessage.phone,
                  },
                })}
              ></StyledFormInput>
              {errors.phone && (
                <StyledErrorMessage>{errors.phone.message}</StyledErrorMessage>
              )}
            </StyledFormItem>
            <StyledFormItem>
              <StyledFormLabel>{LANG.PL.forms.inputs.email}</StyledFormLabel>
              <StyledFormInput
                {...register("email", {
                  required: errorsMessage.required,
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: errorsMessage.email,
                  },
                })}
              ></StyledFormInput>
              {errors.email && (
                <StyledErrorMessage>{errors.email.message}</StyledErrorMessage>
              )}
            </StyledFormItem>
            <StyledFormItem>
              <StyledFormLabel>{LANG.PL.forms.inputs.zipCode}</StyledFormLabel>
              <StyledFormInput
                {...register("zipCode", {
                  required: errorsMessage.required,
                  pattern: {
                    value: /^\d{2}(?:[-\s]\d{3})?$/i,
                    message: errorsMessage.zipCode,
                  },
                })}
              ></StyledFormInput>
              {errors.zipCode && (
                <StyledErrorMessage>
                  {errors.zipCode.message}
                </StyledErrorMessage>
              )}
            </StyledFormItem>
            <StyledFormItem>
              <StyledFormLabel>{LANG.PL.forms.inputs.city}</StyledFormLabel>
              <StyledFormInput
                {...register("city", {
                  required: errorsMessage.required,
                })}
              ></StyledFormInput>
              {errors.city && (
                <StyledErrorMessage>{errors.city.message}</StyledErrorMessage>
              )}
            </StyledFormItem>
            <StyledFormItem>
              <Button type="submit">{LANG.PL.forms.inputs.offerBtn}</Button>
            </StyledFormItem>
            <StyledFormLastItem>
              <StyledCheckboxContainer>
                <StyledCheckboxLabel yledCheckboxLabel>
                  <StyledFormCheckbox
                    {...register("policy", {
                      required: errorsMessage.required,
                    })}
                    type="checkbox"
                  />

                  <StyledCheckboxMsg>
                    {LANG.PL.forms.inputs.checkbox}
                    <StyledLinkButton
                      as={Link}
                      to={LANG.PL.forms.inputs.privacyPolicyURL}
                    >
                      {LANG.PL.forms.inputs.privacyPolicy}
                    </StyledLinkButton>
                    .
                  </StyledCheckboxMsg>
                </StyledCheckboxLabel>
              </StyledCheckboxContainer>
            </StyledFormLastItem>
          </StyledForm>
        </StyledFormWrapper>
      )}
    </>
  );
};

export default Form;
