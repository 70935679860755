import styled from "styled-components";
import PanelsBackground from "../../images/contactPageBg.png";

export const StyledHeroWrapper = styled.section`
  min-height: 900px;
  width: 100vw;
  overflow: hidden;
  background: url(${PanelsBackground}) left 100px top 440px / contain no-repeat;
  @media only screen and (max-width: 1024px) {
    background-position: left 50px top 250px;
  }
  @media only screen and (max-width: 425px) {
    background-position: center top 300px;
  }
`;

export const StyledHeroContent = styled.div`
  height: 900px;
  display: flex;
  padding: 0 149px;
  @media only screen and (max-width: 1024px) {
    flex-direction: column;
  }
  @media only screen and (max-width: 768px) {
    padding: 0 50px;
    align-items: center;
  }
  @media only screen and (max-width: 425px) {
    padding: 0 20px;
    height: 600px;
  }
  @media only screen and (max-width: 375px) {
    height: 500px;
  }
`;

export const StyledHeroTitles = styled.div`
  margin-top: 397px;
  min-width: 520px;

  @media only screen and (max-width: 1024px) {
    min-width: 100%;
    margin: 200px auto 0 auto;
    text-align: center;
    justify-content: flex-end;
  }
  @media only screen and (max-width: 425px) {
    margin: 140px auto 0 auto;
  }
`;

export const StyledHeroImageWrapper = styled.div`
  margin-left: 115px;
  margin-top: 150px;
  max-width: 842px;
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: center;
  @media only screen and (max-width: 1024px) {
    margin: 100px auto 0 auto;
  }
`;

export const StyledHeroImage = styled.img`
  width: 100%;
  height: auto;
`;

export const StyledHeroArrow = styled.img`
  height: 41px;
  width: 41px;
  margin-left: 3%;
  @media only screen and (max-width: 768px) {
    transform: scale(0.7);
    display: inline-block;
    vertical-align: middle;
  }
`;
