import styled from "styled-components";

import arrowIcon from "../../images/arrowIcon.png";

export const StyledFormWrapper = styled.div`
  height: 100%;
  width: 100%;
  @media only screen and (max-width: 768px) {
    margin-top: 40px;
  }
`;
export const StyledForm = styled.form`
  height: 75.6%;
  display: grid;
  grid-template-columns: 50% 50%;
  grid-template-rows: 33.3% 33.3% 33.3%;
  @media only screen and (max-width: 768px) {
    display: flex;
    flex-direction: column;
    height: 90%;
  }
`;

export const StyledFormItem = styled.div`
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;

  @media only screen and (max-width: 768px) {
    align-items: center;
    justify-content: center;
  }
`;

export const StyledFormLastItem = styled.div`
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  grid-column: 1 / 3;

  @media only screen and (max-width: 768px) {
    align-items: flex-start;
    padding: 0 8%;
  }
`;

export const StyledFormLabel = styled.label`
  font-size: ${({ theme }) => theme.fontSizes.small};
  font-weight: ${({ theme }) => theme.fontWeights.regular};
  color: ${({ theme }) => theme.colors.lighterBlack};
  padding-bottom: 6px;
  @media only screen and (max-width: 768px) {
    padding-bottom: 15px;
  }
`;
export const StyledFormInput = styled.input`
  background-color: ${({ theme }) => theme.colors.grey};
  font-size: ${({ theme }) => theme.fontSizes.xsmall};
  font-weight: ${({ theme }) => theme.fontWeights.regular};
  color: ${({ theme }) => theme.colors.lighterBlack};
  font-family: "Titillium Web", sans-serif;
  border: none;
  box-shadow: 0 8px 24px 0 rgba(0, 0, 0, 0.04);
  border-radius: 8px;
  height: 37.6%;
  width: 85%;
  outline: none;
  padding-left: 10px;
  position: relative;
`;

export const StyledErrorMessage = styled.p`
  font-size: ${({ theme }) => theme.fontSizes.xsmall};
  font-weight: ${({ theme }) => theme.fontWeights.regular};
  color: red;
  position: absolute;
  bottom: -26%;
  left: 2%;
  @media only screen and (max-width: 768px) {
    top: 75%;
    left: 0;
    width: 100%;
    text-align: center;
  }
  @media only screen and (max-width: 425px) {
    top: 77%;
    font-size: ${({ theme }) => theme.fontSizes.xxsmall};
  }
`;

export const StyledMessageBox = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const StyledMessage = styled.div`
  background-color: ${({ success, theme }) =>
    success ? theme.colors.success : theme.colors.error};
  color: #fff;
  border-radius: 20px;
  font-size: ${({ theme }) => theme.fontSizes.medium};
  font-weight: ${({ theme }) => theme.fontWeights.semiBold};
  text-align: center;
  padding: 30px 0;
  min-height: 84px;
  width: 80%;
  max-width: 1020px;
  @media only screen and (max-width: 768px) {
    font-size: ${({ theme }) => theme.fontSizes.small};
  }
`;

export const StyledLinkButton = styled.button`
  color: ${({ theme }) => theme.colors.lighterBlack};
  font-size: ${({ theme }) => theme.fontSizes.small};
  font-weight: ${({ theme }) => theme.fontWeights.semiBold};
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

export const StyledCheckboxContainer = styled.div`
  display: block;
  margin: 10px auto 20px auto;
  width: 100%;
  max-width: 1020px;
  text-align: left;
`;

export const StyledCheckboxMsg = styled.span`
  margin-left: 23px;
  padding: 12px 0 26px;
  display: inline-block;
  width: calc(100% - 70px);
  vertical-align: middle;
  padding-top: 22px;
  color: ${({ theme }) => theme.colors.lighterBlack};
  font-size: ${({ theme }) => theme.fontSizes.small};
  @media only screen and (max-width: 1130px) {
    font-size: ${({ theme }) => theme.fontSizes.xsmall};
  }
`;

export const StyledFormCheckbox = styled.input`
  &:checked {
    &:before {
      content: "\\2714";
      font-size: 2rem;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  &:before {
    background-color: #fff;
    content: "";
    display: inline-block;
    height: 28px;
    width: 28px;
    border-radius: 4px;
    cursor: pointer;
  }
`;

export const StyledCheckboxLabel = styled.label`
  cursor: pointer;
`;
