import styled from "styled-components";

export const StyledContactWrapper = styled.section`
  height: 650px;
  max-width: 1360px;
  width: 82%;
  margin: 0 auto;
  @media only screen and (max-width: 768px) {
    height: 1200px;
  }
  @media only screen and (max-width: 500px) {
    width: 95%;
  }
`;

export const StyledContactDiv = styled.div`
  width: 100%;
  height: 84.6%;
  background-image: linear-gradient(61deg, #fcc615 -1%, #f5bf0e 105%);
  border-radius: 20px;
  display: grid;
  grid-template-columns: 48% 52%;
  @media only screen and (max-width: 768px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;
export const StyledContactTitles = styled.div`
  padding: 170px 0 0 50px;
  @media only screen and (max-width: 1024px) {
    padding: 100px 0 0 50px;
  }
  @media only screen and (max-width: 768px) {
    padding: 5% 0 0 0;
    position: relative;
    text-align: center;
  }
`;
export const StyledContactForm = styled.div`
  height: 100%;
  width: 100%;
`;

export const StyledContactIcon = styled.img`
  padding-left: 30px;

  @media only screen and (max-width: 768px) {
    transform: rotate(90deg) scale(0.7);
    display: inline-block;
    vertical-align: middle;
    padding-left: 0%;
    padding-bottom: 5%;
  }
`;
