import React from 'react';
import { graphql } from 'gatsby';
import {
  StyledBlogContent,
  StyledImage,
  StyledArticleContent,
  StyledArticleTitle,
  StyledArticleSection,
} from './layout.style';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import Layout from './layout.component';
import Contact from '../contact/contact.component';
import Footer from '../../sections/footer/footer.component';
import SubpageHero from '../subpageHero/subpageHero.component';
import { StyledCenter } from '../../utils/styledComponents/center.style';
import SEO from '../seo.component';
import { LANG } from '../../lang/lang';

type FixedItem = {
  height: number;
  width: number;
  src: string;
  srcSet: string;
  tracedSVG: string;
};
type Fixed = {
  fixed: FixedItem;
};

type Frontmatter = {
  date: string;
  slug: string;
  title: string;
  featuredImage: {
    childImageSharp: Fixed;
  };
};

type Mdx = {
  body: string;
  frontmatter: Frontmatter;
};

type LayoutArticlePropsType = {
  data: {
    mdx: Mdx;
  };
};

function LayoutArticle({ data }: LayoutArticlePropsType): JSX.Element {
  return (
    <Layout>
      <SEO title={`Blog - ${data.mdx.frontmatter.title.replace('<b>', '').replace('</b>', '')}`} />
      <SubpageHero title={LANG.PL.blogSubpage.subTitle} subTitle={LANG.PL.blogSubpage.title}>
        <StyledCenter>
          <StyledArticleContent>
            <StyledArticleTitle>{data.mdx.frontmatter.title}</StyledArticleTitle>
            <StyledArticleSection>
              <StyledBlogContent>
                <StyledImage src={data.mdx.frontmatter.featuredImage.childImageSharp.fixed.src} />
                <MDXRenderer>{data.mdx.body}</MDXRenderer>
              </StyledBlogContent>
            </StyledArticleSection>
          </StyledArticleContent>
        </StyledCenter>
        <Contact />
        <Footer />
      </SubpageHero>
    </Layout>
  );
}

export default LayoutArticle;

export const query = graphql`
  query querySingleArticle($slug: String!) {
    mdx(frontmatter: { slug: { eq: $slug } }) {
      frontmatter {
        title
        date
        slug
        featuredImage {
          childImageSharp {
            fixed(width: 1500) {
              ...GatsbyImageSharpFixed_tracedSVG
            }
          }
        }
      }
      body
    }
  }
`;
